import type { OverrideValues } from "@shared/context/experiment/experiment.store";

import { PERSISTED_GROUPS_KEY } from "./constants";

const getPersistedOverrides = (): OverrideValues => {
  try {
    const serializedPersistedGroups = localStorage.getItem(PERSISTED_GROUPS_KEY);

    if (serializedPersistedGroups) {
      const persistedGroups = JSON.parse(serializedPersistedGroups);

      return persistedGroups;
    }
  } catch {
    // do nothing, fallback to default return value
  }

  return {};
};

export { getPersistedOverrides };
