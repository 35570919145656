import type { SerializedStyles } from "@emotion/react";
import { faEyeSlash, faEye } from "@fortawesome/pro-regular-svg-icons";
import type { InputHTMLAttributes, ReactNode } from "react";
import { useState } from "react";
import { a, useTransition } from "react-spring";

import { useFloatingLabelFocus } from "@aviary/components/FloatingLabelInput/useFloatingLabelFocus";
import { FormFieldInformation } from "@aviary/components/FormFieldInformation";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./DeprecatedFloatingLabelPassword.styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Unique field ID
   *
   * @required
   */
  id: string;
  /**
   * Label for the floating text input
   *
   * @required
   */
  label: string;
  /**
   * Allows hiding of the label
   *
   * @default false
   */
  hideLabel?: boolean;
  /**
   * Callback from the HTML element on change
   */
  handleChange?: any;
  /**
   * Adds padding if there is an icon present in the input
   *
   * @default false
   */
  hasIcon?: boolean;
  /**
   * Array of field-level errors that is passed into <FormFieldInformation />
   */
  errors?: ReactNode[];
  /**
   * Text shown below the input to describe it's function
   */
  descriptiveText?: ReactNode;
  /**
   * Adds in custom styles to the wrapper div
   */
  wrapperStyles?: SerializedStyles;
  /**
   * Adds in custom styles to the HTML input
   */
  inputStyles?: SerializedStyles;
  /**
   * Specifies whether the input's background is white or gray05
   *
   * @default false
   */
  hasWhiteBackground?: boolean;
}

const DeprecatedFloatingLabelPassword = ({
  value,
  disabled,
  id,
  label,
  hideLabel,
  handleChange,
  descriptiveText,
  name,
  onBlur,
  hasIcon,
  errors,
  wrapperStyles,
  inputStyles,
  hasWhiteBackground,
  ...rest
}: Props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { isFocused, handleFocus, handleBlur, inputElement } = useFloatingLabelFocus(onBlur);

  const hasErrors = (): boolean => {
    return errors && errors.length > 0;
  };

  const filledStyles = elementStyle => {
    if (hideLabel) {
      return elementStyle.filled;
    }

    return [elementStyle.filled, elementStyle.filledWithLabel];
  };

  const conditionalFillStyles = elementStyle => {
    return [
      elementStyle.base,
      Boolean(value) && filledStyles(elementStyle),
      isFocused && elementStyle.focused,
      isFocused && filledStyles(elementStyle),
      !isFocused && hasErrors() && elementStyle.errorStyles,
      disabled && elementStyle.disabled,
      hasIcon && elementStyle.hasIcon,
      hideLabel && elementStyle.hidden,
      hasWhiteBackground && elementStyle.hasWhiteBackground,
    ];
  };

  const conditionalColorStyles = () => {
    return [hideLabel && styles.label.hidden];
  };

  const transitions = useTransition(Boolean(value), {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleEyeClick = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const renderInput = () => {
    return (
      <input
        css={[conditionalFillStyles(styles.htmlInput), inputStyles]}
        id={id}
        value={value}
        type={isPasswordShown ? "text" : "password"}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        name={name}
        aria-errormessage={`${id}Error`}
        aria-invalid={hasErrors()}
        ref={inputElement}
        {...rest}
      />
    );
  };

  const renderPasswordToggle = () => {
    return transitions((style, item) => {
      return (
        item && (
          <a.div
            style={style}
            css={[
              styles.passwordToggle.base,
              hideLabel && styles.passwordToggle.hideLabel,
              disabled && styles.passwordToggle.disabled,
            ]}
            onClick={handleEyeClick}
          >
            <FontAwesomeIcon fixedWidth icon={isPasswordShown ? faEye : faEyeSlash} />
          </a.div>
        )
      );
    });
  };

  const renderRequiredAsterisk = () => {
    return (
      <span aria-hidden css={styles.requiredAsterisk}>
        *
      </span>
    );
  };

  return (
    <div css={[styles.floatingLabelInputErrorWrapper, wrapperStyles]}>
      <div css={styles.floatingLabelWrapper}>
        <label css={[conditionalFillStyles(styles.label), conditionalColorStyles()]} htmlFor={id}>
          {label}
          {rest?.required && renderRequiredAsterisk()}
        </label>
        {renderInput()}
        {renderPasswordToggle()}
      </div>
      <FormFieldInformation
        id={id}
        disabled={disabled}
        errors={!isFocused && errors}
        descriptiveText={descriptiveText}
      />
    </div>
  );
};

export type { Props as FloatingLabelPasswordProps };
export { DeprecatedFloatingLabelPassword };
