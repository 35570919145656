export const h1 = "2.5rem";
export const h2 = "2rem";
export const h3 = "1.5rem";
export const h4 = "1.25rem";
export const h5 = "1rem";

export const h1Mobile = "2rem";
export const h2Mobile = "1.75rem";
export const h3Mobile = "1.375rem";
export const h4Mobile = "1.25rem";
export const h5Mobile = "1rem";

export const body = "1rem";
export const footnote = "0.875rem";
export const caption = "0.75rem";

export const smallLabel = "0.875rem";

export const paragraphShort = "1.125rem";
export const paragraphLong = "1rem";

// Font weights
export const bold = "900";
export const semiBold = "700";
export const strong = "600";
export const regular = "500";
export const light = "400";

// Font families
export const familySansSerif = `"Mulish", -apple-system, "Helvetica", "Arial", sans-serif`;
export const headerFont = familySansSerif;
export const bodyFont = familySansSerif;
